import { type AnalyticsEvent, AnalyticsEventType } from '@/router/analytics/analyticsData';

declare const dataLayer: AnalyticsEvent[];

class Analytics {
  pageView(page: string, title: string) {
    const params = {
      page_path: page, 
      page_title: title,
    };

    this.event('page_view', params);
  }

  event(eventName: string, params: Record<string, unknown>) {
    window.gtag('event', eventName, params);
  }

  trackButtonClick(buttonName: string) {
    const params = {
      button_name: buttonName,
    };

    this.event('button_click', params);
  }

  trackTabClick(tabName: string, tabIndex: number) {
    const params = {
      tab_name: tabName,
      tab_index: tabIndex,
    };

    this.event('tab_click', params);
  }

  trackFilterClick(filterName: string) {
    const params = {
      filter_name: filterName,
    };

    this.event('filter_click', params);
  }

  trackSortClick(sortName: string, sortDirection: string) {
    const params = {
      sort_name: sortName,
      sort_direction: sortDirection,
    };

    this.event('sort_click', params);
  }
}

export default new Analytics();
