import type { RouteRecordRaw } from 'vue-router';
import { defaultGuardProvider, savedPageGuardProvider } from './helpers';
import uavRoutes from './uav';

const base: Array<RouteRecordRaw> = [
  {
    path: '/',
    beforeEnter: defaultGuardProvider(),
    redirect: () => {
      return { path: '/assetsInsightsCases' };
    },
    meta: {
      title: 'AIC',
    },
  },
  {
    path: '/assetsInsightsCases',
    name: 'AssetsInsightsCases',
    redirect: () => {
      const isFirstVisitAfterLogin = sessionStorage.getItem('isFirstVisitAfterLogin');
      if (isFirstVisitAfterLogin === null) {
        sessionStorage.setItem('isFirstVisitAfterLogin', 'true');
        localStorage.setItem('lastVisitedRoute', '/assetsInsightsCases/insights');
      }
      const lastVisitedRoute = localStorage.getItem('lastVisitedRoute');
      return lastVisitedRoute || '/assetsInsightsCases/insights';
    },
    beforeEnter: defaultGuardProvider(),
    component: () => import('@/features/aic/views/AICView.vue'),
    meta: {
      title: 'AIC',
    },
    children: [
      {
        name: 'AIC Insights',
        path: 'insights',
        beforeEnter: defaultGuardProvider(),
        component: () => import('@/features/insights/views/Insights.vue'),
        meta: {
          title: 'AIC - Insights',
        },
      },
      {
        name: 'AIC Assets',
        path: 'assets',
        beforeEnter: defaultGuardProvider(),
        component: () => import('@/features/assets/views/AssetsView.vue'),
        meta: {
          title: 'AIC - Assets',
        },
      },
      {
        name: 'AIC Cases',
        path: 'cases',
        beforeEnter: defaultGuardProvider(),
        component: () => import('@/features/cases/views/CasesView.vue'),
        meta: {
          title: 'AIC - Cases',
        },
      },
    ],
  },
  {
    path: '/RiskExplorer',
    name: 'Risk Explorer',
    beforeEnter: savedPageGuardProvider('RiskExplorer'),
    component: () => import('@/features/riskExplorer/views/RiskExplorerView.vue'),
    meta: {
      title: 'Risk Explorer',
    },
  },
  {
    path: '/AssetInsightsSummary',
    name: 'Asset Insights Summary',
    beforeEnter: defaultGuardProvider(),
    component: () => import('@/features/insights/views/AssetInsightsSummaryView.vue'),
    meta: {
      title: 'Asset Insights Summary'
    },
  },
  {
    path: '/Terms',
    name: 'Terms',
    component: () => import('@/features/auth/views/TermsView.vue'),
    meta: {
      hideSidebar: true,
      title: 'Terms and Conditions',
    },
  },
  {
    path: '/Privacy',
    name: 'Privacy',
    component: () => import('@/features/auth/views/PrivacyView.vue'),
    meta: {
      hideSidebar: true,
      title: 'Privacy',
    },
  },
  {
    path: '/logout',
    name: 'Logout',
    beforeEnter: (to, from, next) => {
      defaultGuardProvider()(to, from, next);
    },
    component: () => import('@/features/auth/views/LogoutView.vue'),
  },
  {
    path: '/uav/:view/:id',
    name: 'UAV',
    beforeEnter: defaultGuardProvider(),
    component: () => import('@/features/uav/views/UAV.vue'),
    meta: { title: 'UAV' },
  },
  {
    path: '/uav/:view/:type/:id',
    name: 'UAV-Cases',
    beforeEnter: defaultGuardProvider(),
    component: () => import('@/features/uav/views/UAV.vue'),
    meta: { title: 'UAV - Cases' },
  },
  {
    path: '/401',
    component: () => import('@/features/auth/views/401View.vue'),
    meta: { title: '401 Page' },
  },
  {
    path: '/:pathMatch(.*)*',
    component: () => import('@/features/auth/views/404View.vue'),
    meta: { title: '404 Page' },
  },
  uavRoutes,
];

export default base;
