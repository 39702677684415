import type { Taxonomy } from '@/api/routes/Taxonomy';
import type { Assets, Taxonomy as TaxonomySchema, WorkOrders } from '@/api/schema';
import { computed, reactive } from 'vue';
import type { FilterObject, FilterValues, Filter, Option } from '@/features/userSavedFilters/types/userSavedFiltersTypes';
import type { Enum } from 'ts-algebra/lib/meta-types';
import { insightSeverityEnum } from '@/features/insights/types/insights';
// TODO: refactor
export function filterUtils() {
  const processTaxonomy = (taxonomy: Taxonomy[]) => {
    const result: { [key: string]: any } = {};

    // Process Taxonomy API data response
    taxonomy.forEach((taxonomy: Taxonomy) => {
      const level = taxonomy.level;
      const item = taxonomy.item;

      // Create firt level
      if (!result[level]) {
        result[level] = [];
      }

      result[level].push(item);

      const getChildren = (tax: Taxonomy[], parentLevel: string, parentName: string) => {
        Object.values(tax).forEach((_taxonomy: Taxonomy) => {
          const _level = _taxonomy.level;
          const _item = `${parentName}_${_taxonomy.item}`;

          // Create next levels (MSC, REG, BN, AAC/UIC)
          if (!result[_level]) {
            result[_level] = {};
          }

          // Create key linked values
          if (!result[_level][parentName]) {
            result[_level][parentName] = [];
          }

          // Add items to level
          const _itemOBJ = {
            parentLevel,
            parentName,
            level: _level,
            item: _item,
          };
          result[_level][parentName].push(_itemOBJ);

          if (_taxonomy.children && _taxonomy.children.length > 0) {
            getChildren(_taxonomy.children, _level, _item);
          }
        });
      };

      if (taxonomy.children) {
        getChildren(taxonomy.children, level, item);
      }
    });
    return { result };
  };
  function formatFilterFromTaxonomyNodeDto(taxonomyNodeItemArray: TaxonomySchema['TaxonomyNodeDto'][]) {
    const filterObject: Assets['SitewideFilterRequest'] = {
      filter: {},
    };
    taxonomyNodeItemArray.map((taxonomyNodeItem) => {
      if (taxonomyNodeItem && taxonomyNodeItem.item && taxonomyNodeItem.level) {
        if (
          filterObject.filter[taxonomyNodeItem.level] !== undefined &&
          (!filterObject.filter[taxonomyNodeItem.level].includes(taxonomyNodeItem.item) ||
            taxonomyNodeItem.parentValue !== undefined)
        ) {
          filterObject.filter[taxonomyNodeItem.level].push(taxonomyNodeItem.item);
          return;
        }
        filterObject.filter[taxonomyNodeItem.level] = [taxonomyNodeItem.item];
        return;
      }
    });
    return filterObject;
  }
  const permissionsToFilters = (userTaxonomyTree: any): Assets['SitewideFilterRequest'] => {
    const taxonomyNodeItemArray: TaxonomySchema['TaxonomyNodeDto'][] = [];
    if (userTaxonomyTree && userTaxonomyTree.userPermissions && userTaxonomyTree.userPermissions.permissions) {
      userTaxonomyTree.userPermissions.permissions.map((permission: any) => {
        permission.taxonomyPath.map((taxonomy: TaxonomySchema['TaxonomyNodeDto']) => {
          taxonomyNodeItemArray.push(taxonomy);
        });
      });
    }
    return formatFilterFromTaxonomyNodeDto(taxonomyNodeItemArray);
  };
  const allPermissionsToFilters = (
    userTaxonomyTree: TaxonomySchema['UserTaxonomyTreeDto']
  ): Assets['SitewideFilterRequest'] => {
    const taxonomyNodeItemArray: TaxonomySchema['TaxonomyNodeDto'][] = [];
    if (userTaxonomyTree && userTaxonomyTree.tree) {
      userTaxonomyTree.tree.map((treeItem: any) => {
        taxonomyNodeItemArray.push({
          item: treeItem.item,
          level: treeItem.level,
        });
        return;
      });
    }
    return formatFilterFromTaxonomyNodeDto(taxonomyNodeItemArray);
  };
  const isEmpty = (obj: any) => {
    return Object.keys(obj).length === 0 && obj.constructor === Object;
  }

  const combineFilters = (filterOne: Assets['SitewideFilterRequest'], filterTwo: Assets['SitewideFilterRequest']) => {
    let filterObject: Assets['SitewideFilterRequest'];
    if (filterOne && filterTwo) {
      const combinedObjects = { ...filterOne.filter, ...filterTwo.filter };
      filterObject = { filter: combinedObjects };
    }
    return filterObject!.filter || [];
  };

  const combineObjects = (obj1:any, obj2:any) => {
    for (const key in obj1) {
        if (!obj2.hasOwnProperty(key)) {

          obj2 = {...obj2, [key]: obj1[key]};
        }
    }
    return obj2;
  }

  const combineAssetFilters = (ObjA:any, ObjB: any) => {
    ObjA.forEach((objA:any) => {
      objA.options.forEach(optionA => {
        const objB = ObjB.find(objB => objB.id === objA.id);
        if (objB) {
          const optionB = objB.options.find(optionB => optionB.value === optionA.value);
          if (!optionB && !optionA.disabled && optionA.selected) {
            optionA.disabled = true;
            optionA.selected = false;
          }
        } else {
          if (!optionA.disabled && optionA.selected) {
            optionA.disabled = true;
            optionA.selected = false;
          }
        }
      });
    });
    return ObjA;
  }

  const filterCollectionFilters = (collectionFilters: any, attributes: any) => {  
    const keysToExclude = attributes;
    const filteredCollectionFilters = {};
  
    for (const key in collectionFilters) {
      if (!keysToExclude.includes(key)) {
        filteredCollectionFilters[key] = collectionFilters[key];
      }
    }
    return filteredCollectionFilters;
  }

  const resetSelections = (objA:any) => {
    objA.forEach((obj:any) => {
      obj.options.forEach((option:any) => {
        if (!option.selected && !option.disabled) {
          option.selected = true;
        }
      });
    });
  
    return objA;
  }

  const setSelections = (attributeArray: any, object:any, array:any) => {
    const attributeSet = new Set(attributeArray);
    const collectionFilters = object.filters.attributeFilters.collectionFilters;

    const arrayMap = array.reduce((map:any, item:any ) => {
        map[item.title] = item;
        return map;
    }, {});

    for (const key of attributeSet) {
        if (collectionFilters.hasOwnProperty(key)) {
            const item = arrayMap[key];
            const options = item.options.map((option:any) => {
                if (collectionFilters[key].includes(option.value)) {
                    return { ...option, selected: true };
                } else {
                    return { ...option, selected: false };
                }
            });
            arrayMap[key] = { ...item, options };
        }
    }

    return Object.values(arrayMap);
  }

  const dropdownItemToFilter = (dropdownFilter: Filter): Assets['SitewideFilterRequest'] => {
    const taxonomyNodeItemArray: TaxonomySchema['TaxonomyNodeDto'][] = [];
    dropdownFilter.options.map((option: Option) => {
      if (option.selected) {
        taxonomyNodeItemArray.push({
          // item: option.value,
          item: option.title,
          level: dropdownFilter.level,
          parentValue: option.parentValue,
        });
      }
    });
    return formatFilterFromTaxonomyNodeDto(taxonomyNodeItemArray);
  };

  const generateSelectedTaxonomy = (tempTaxonomy: any, collectionItemAll: any, collectionItemKeys: any) => {
    const taxonomy = [...tempTaxonomy];

    function dataToChange() {
      const testArrayObj: any = {};
    
      collectionItemKeys.forEach((collectionItems: any) => {
        taxonomy.forEach((taxonomyItem) => {
          if (collectionItems === taxonomyItem.level) {
            if (!testArrayObj[collectionItems]) {
              testArrayObj[collectionItems] = { ...taxonomyItem, options: [] };
            }
            const collectionItemSet = new Set(collectionItemAll[collectionItems]);
    
            taxonomyItem.options.forEach((taxonomyOption: any) => {
              const optionExists = testArrayObj[collectionItems].options.find(
                (option: any) => option.value === taxonomyOption.value && option.selected === true
              );
    
              if (!optionExists) {
                if (collectionItemSet.has(taxonomyOption.value) || collectionItemSet.has(taxonomyOption.title)) {
                  testArrayObj[collectionItems].options.push({ ...taxonomyOption, selected: true });
                } else {
                  testArrayObj[collectionItems].options.push({ ...taxonomyOption, selected: false });
                }
              }
            });
          }
        });
      });
      return Object.values(testArrayObj);
    }
  
    const objectsWithChanges = dataToChange();

    const temporaryTaxonomyArray = taxonomy.map((item) => {
      const updatedItem = objectsWithChanges.find((updated: any) => updated.id === item.id);
      return updatedItem ? updatedItem : item;
    });

    temporaryTaxonomyArray.forEach((filter: any) => {

    filter.options.forEach((option: any) => {
      const parentFilter = temporaryTaxonomyArray.find((parentFilter: any) => parentFilter.level == option.parentLevel);
      if (parentFilter) {  
        const parentFilterOption = parentFilter.options.find((_option: any) => _option.value == option.parentValue);
        if(parentFilterOption) {  
          option.disabled = !parentFilterOption.selected;
          if(option.disabled) {
            option.selected = false;
          }
        }
      }
    });

      filter.options.sort((a: any, b: any) => {
        return a.title >= b.title ? 1 : -1;
      });
    });

    return temporaryTaxonomyArray;
  };

  const generateTaxonomyFilters = (taxonomy: { [s: string]: unknown } | ArrayLike<unknown>) => {
    const KEYS_FIRST_LEVEL = Object.keys(taxonomy);
    const FIRST_LEVEL_KEY = KEYS_FIRST_LEVEL[0];

    const stepOne: { [key: string]: any } = {};

    let index = 0;

    for (const [key, value] of Object.entries(taxonomy)) {
      const isFirstLevel = key === FIRST_LEVEL_KEY;

      // Firts level values are Array<String>
      const optionsTarget = isFirstLevel
        ? (value as unknown as Array<any>)
        : Object.values(value as Record<string, any>);

      // Create Options
      const options: Array<any> = [];
      optionsTarget.forEach((_option: any) => {
        if (isFirstLevel) {
          options.push({
            value: _option,
            title: _option,
            selected: true,
          });
          return;
        }

        _option.forEach((option: any) => {
          options.push({
            value: option.item,
            title: option.item.split('_')[option.item.split('_').length - 1],
            selected: true,
            parentValue: option.parentName,
            parentLevel: option.parentLevel,
            disabled: false,
            searchResult: false,
          });
        });
      });

      // Cerate Filter object
      const filter: Filter = {
        id: index,
        title: key,
        level: key,
        name: key,
        tag: key,
        options,
        search: '',
      };

      stepOne[key] = filter;
      index++;
    }

    // Taxonomy filters step one - Unit Filters
    const filters: Array<Filter> = reactive(Object.values(stepOne));

    filters.forEach((filter: Filter) => {
      if (filter.level === FIRST_LEVEL_KEY) {
        return;
      }

      filter.options.forEach((option: Option) => {
        const parentLevel = filters.filter((filter: Filter) => {
          return filter.level == option.parentLevel;
        })[0];

        const parentFilterOption = parentLevel.options.filter((_option: Option) => {
          if (option.parentLevel == FIRST_LEVEL_KEY) {
            return _option.value == option.parentValue!.split('_')[0];
          } else {
            return _option.value == option.parentValue;
          }
        })[0];

        option.disabled = computed(() => {
          return !parentFilterOption.selected;
        }).value;
      });

      filter.options.sort((a, b) => {
        return a.title >= b.title ? 1 : -1;
      });
    });

    return filters;
  };
  const generateAssetFilters = (taxonomy: any, filters: Array<any>) => {
    const filtersObjects: any = [];

    filters.forEach((filterValue, index) => {
      filtersObjects.push({
        id: index,
        title: filterValue,
        tag: filterValue,
        level: filterValue,
        options: [],
        search: '',
      });
    });

    filtersObjects.forEach((filter: any) => {
      taxonomy[filter.level]?.forEach((value: any) => {
        if (value.length > 0) {
          filter.options.push({
            title: value,
            value: value,
            selected: true,
            disabled: false,
            searchResult: false,
          });
        }
      });
    });
    return filtersObjects;
  };

  /**
   *
   * @param workOrderFilter
   * @returns A formated filter object for checkbox dropdown or inline checkbox dropdown
   */
  const generateComponentHealthFilters = (workOrderFilter: FilterValues): FilterObject => {
    const filtersObjects: any = {};
    for (const filter in workOrderFilter) {
      filtersObjects[filter] = {
        id: filter,
        title: filter,
        tag: filter,
        level: filter,
        selectedItems: [],
        options: [],
        search: '',
      };
    }

    Object.keys(filtersObjects).forEach((filter: any) => {
      for (let index = 0; index < workOrderFilter[filter].length; index++) {
        const element = workOrderFilter[filter][index];
        if (element !== undefined) {
          filtersObjects[filter].options.push({
            title: element,
            value: element,
            selected: false,
            disabled: false,
            searchResult: false,
          });
        }
      }
    });
    return filtersObjects;
  };

  /**
   *
   * @param workOrderFilter
   * @param excludedItems
   * @param updatedTitles
   * @returns A formated filter object for checkbox dropdown or inline checkbox dropdown from a AssetTaskFiltersDto
   * can exclude and update filter titles
   */
  const generateWorkOrderDropdownFilters = (
    workOrderFilter: WorkOrders['AssetTaskFiltersDto'],
    excludedItems: string[] = [],
    updatedTitles?: { [key: string]: string }
  ): FilterObject => {
    const checkTitleUpdate = (title: string) => {
      if (updatedTitles && Object.keys(updatedTitles).includes(title)) {
        return updatedTitles[title];
      } else {
        return title;
      }
    };

    const filterObjectUpdate = (attribute: string) => {
      filtersObjects[attribute] = {
        id: attribute,
        title: checkTitleUpdate(attribute),
        tag: attribute,
        level: attribute,
        selectedItems: [],
        options: [],
        search: '',
      };
    };

    const updateOptions = (element: string | number, filterKey: string) => {
      if (element !== undefined) {
        filtersObjects[filterKey].options.push({
          title: element,
          value: element,
          selected: false,
          disabled: false,
          searchResult: false,
        });
      }
    };

    const filtersObjects: any = {};
    for (const filter in workOrderFilter) {
      if (filter === 'assetAttributes') {
        for (const attribute in workOrderFilter[filter]) {
          if (!excludedItems.includes(attribute)) {
            filterObjectUpdate(attribute);
          }
        }
      } else if (!excludedItems.includes(filter)) {
        filterObjectUpdate(filter);
      }
    }

    Object.keys(filtersObjects).forEach((filter: string) => {
      if (workOrderFilter?.assetAttributes && Object.keys(workOrderFilter.assetAttributes).includes(filter)) {
        for (let index = 0; index < workOrderFilter.assetAttributes[filter].length; index++) {
          const element = workOrderFilter.assetAttributes[filter][index];
          updateOptions(element, filter);
        }
      } else {
        const workOrderFilterArray = workOrderFilter![filter] as Array<string>;
        for (let index = 0; index < workOrderFilterArray.length; index++) {
          const element = workOrderFilterArray[index];
          updateOptions(element, filter);
        }
      }
    });

    const sortedFiltersObjects: any = {};
    // sort filters Objects by updatedTitles keys order
    if (updatedTitles) {
      Object.keys(updatedTitles).forEach((title) => {
        if (Object.keys(filtersObjects).includes(title)) {
          sortedFiltersObjects[title] = filtersObjects[title];
        }
      });
      return sortedFiltersObjects;
    }

    return sortedFiltersObjects || filtersObjects;
  };

  /**
   *
   * @param newFilters
   * @param currentFilters
   * @returns an updated filter object with the "newFilters" as active and the options not in newFilters are disabled
   */
  const updateDisabledFilters = (
    newFilters: WorkOrders['AssetTaskFiltersDto'],
    currentFilters: FilterObject
  ): FilterObject => {
    const updatedFilters = currentFilters;
    for (const filter in newFilters) {
      if (filter === 'assetAttributes') {
        for (const attribute in newFilters[filter]) {
          if (updatedFilters[attribute]) {
            updatedFilters[attribute].options.forEach((option) => {
              if (
                newFilters?.assetAttributes &&
                !newFilters?.assetAttributes[attribute]?.includes(option.value.toString())
              ) {
                option.disabled = true;
              } else {
                option.disabled = false;
              }
            });
          }
        }
      } else if (updatedFilters[filter]) {
        updatedFilters[filter].options.forEach((option:any) => {
          if (!newFilters[filter].includes(option.value)) {
            option.disabled = true;
          } else {
            option.disabled = false;
          }
        });
      }
    }
    return updatedFilters;
  };

  const filtersToQuerystring = (filters: any) => {
    // let querystring = '';
    if (filters && filters != undefined) {
      const allSelected = (options: Array<Option>) => {
        let result = true;
        options.forEach((option: Option) => {
          if (!option.selected && !option.disabled) {
            result = false;
            return;
          }
        });
        return result;
      };

      const selected: { [key: string]: any } = {};
      if (filters.taxonomy != undefined) {
        filters.taxonomy.forEach((filter: any) => {
          selected[filter.level!] = [];
        });
      }

      filters.asset?.forEach((filter: any) => {
        let key = filter.level!;

        // PATCH for Federal
        switch (key.toLowerCase()) {
          case 'family':
            key = 'nomenclature';
            break;
          case 'serial':
            key = 'serial_number';
            break;
        }
        selected[key] = [];
      });

      // Collect selected taxonomy filters
      if (filters.taxonomy) {
        const FIRST_LEVEL_KEY = filters.taxonomy[0].level;

        filters.taxonomy.forEach((filter: Filter) => {
          const level = filter.level!;
          const isFirstLevel = level == FIRST_LEVEL_KEY;

          if (allSelected(filter.options)) {
            return;
          }

          filter.options.forEach((option: any) => {
            const disable = isFirstLevel ? false : option.disabled;

            // if (option.selected && !disable && !selected[level].includes(option.value)) {
            if (option.selected && !disable) {
              const value = option.value.split('_')[option.value.split('_').length - 1];
              selected[level].push(value);
            }
          });
        });
      }

      // Collect selected asset filters
      if (filters.asset) {
        filters.asset.forEach((filter: Filter) => {
          if (allSelected(filter.options)) {
            return;
          }

          let level = filter.level;

          switch (level.toLowerCase()) {
            case 'family':
              level = 'nomenclature';
              break;
            case 'serial':
              level = 'serial_number';
              break;
          }

          filter.options.forEach((option: Option) => {
            if (option.selected && selected[level] && !selected[level].includes(option.value)) {
              const value = option.value.split('_')[option.value.split('_').length - 1];
              selected[level].push(value);
            }
          });
        });
      }

      // Remove empty filters lists
      Object.keys(selected).forEach((key: string) => {
        if (selected[key].length < 1) {
          delete selected[key];
        }
      });

      querystring = Object.keys(selected).length ? `filters=${JSON.stringify(selected)}` : '';
    }

    return querystring;
  };

  const filtersToUpdate = (filters: any) => {
    const querystring = '';
    let filtersUpdated;
    if (filters && filters != undefined) {
      const allSelected = (options: Array<Option>) => {
        let result = true;
        options.forEach((option: Option) => {
          if (!option.selected && !option.disabled) {
            result = false;
            return;
          }
        });
        return result;
      };

      const selected: { [key: string]: any } = {};
      if (filters.temporaryTaxonomy != undefined) {
        filters.temporaryTaxonomy.forEach((filter: any) => {
          selected[filter.level!] = [];
        });
      }

      filters.temporaryAssetTaxonomy?.forEach((filter: any) => {
        let key = filter.level!;

        // PATCH for Federal
        switch (key.toLowerCase()) {
          case 'family':
            key = 'nomenclature';
            break;
          case 'serial':
            key = 'serial_number';
            break;
        }
        selected[key] = [];
      });

      // Collect selected taxonomy filters
      if (filters.temporaryTaxonomy) {
        const FIRST_LEVEL_KEY = filters.temporaryTaxonomy[0].level;

        filters.temporaryTaxonomy.forEach((filter: Filter) => {
          const level = filter.level!;
          const isFirstLevel = level == FIRST_LEVEL_KEY;

          if (allSelected(filter.options)) {
            return;
          }

          filter.options.forEach((option: Option) => {
            const disable = isFirstLevel ? false : option.disabled;

            if (option.selected && !disable && !selected[level].includes(option.value)) {
              const value:any = option.value.split('_')[option.value.split('_').length - 1];
              selected[level].push(value);
            }
          });
        });
      }

      // Collect selected asset filters
      if (filters.temporaryAssetTaxonomy) {
        filters.temporaryAssetTaxonomy.forEach((filter: Filter) => {
          if (allSelected(filter.options)) {
            return;
          }

          let level = filter.level;

          switch (level.toLowerCase()) {
            case 'family':
              level = 'nomenclature';
              break;
            case 'serial':
              level = 'serial_number';
              break;
          }

          filter.options.forEach((option: Option) => {
            if (option.selected && selected[level] && !selected[level].includes(option.value)) {
              const value = option.value.split('_')[option.value.split('_').length - 1];
              selected[level].push(value);
            }
          });
        });
      }

      // Remove empty filters lists
      Object.keys(selected).forEach((key: string) => {
        if (selected[key].length < 1) {
          delete selected[key];
        }
      });
      filtersUpdated = selected;
      // querystring = Object.keys(selected).length ? `filters=${JSON.stringify(selected)}` : '';
    }
    return filtersUpdated;
  };

  const deepEqual = (objectA: any, objectB: any) => {
    if (objectA === objectB) return true;
    if (typeof objectA != 'object' || objectA === null || typeof objectB != 'object' || objectB === null) return false;

    const keys1 = Object.keys(objectA);
    const keys2 = Object.keys(objectB);

    if (keys1.length != keys2.length) return false;
    for (const key of keys1) {
      if (!keys2.includes(key) || !deepEqual(objectA[key], objectB[key])) return false;
    }
    return true;
  };

  const getInvalidFilters = (objectA: any, objectB: any) => {
    const optionValues: any = {};

    objectB.forEach((item: any) => {
      if (item.options) {
        item.options.forEach((option: any) => {
          optionValues[option.value.toUpperCase()] = true;
          optionValues[option.title.toUpperCase()] = true;
        });
      }
    });

    const nonMatchingObjects = objectA.filter((objA: any) => {
      if (!objA.filters || !objA.filters.attributeFilters) {
        return false;
      }

      const collectionFilters = objA.filters.attributeFilters.collectionFilters;
      for (const key in collectionFilters) {
        const filterValues = collectionFilters[key];
        for (const filterValue of filterValues) {
          if (!optionValues[filterValue.toUpperCase()]) {
            // console.log(`Invalid filter value: ${filterValue} for object:`, objA);
            return true;
          }
        }
      }
      return false;
    });

    return nonMatchingObjects;
  };

  const generateFilterFromStringArray = (filterId: string, stringArray: string[]): Filter => {
    const filtersObject: any = {
      id: filterId,
      title: filterId,
      tag: filterId,
      level: filterId,
      selectedItems: [],
      options: [],
      search: '',
    };
    stringArray.forEach((value: any) => {
      if (value.length > 0) {
        filtersObject.options.push({
          title: value,
          value: value,
          selected: false,
          disabled: false,
          searchResult: false,
        });
      }
    });
    return filtersObject;
  };

  const generateFilterFromEnum = (filterId: string, filterEnum: Enum): Filter => {
    const filtersObject: any = {
      id: filterId,
      title: filterId,
      tag: filterId,
      level: filterId,
      selectedItems: [],
      options: [],
      search: '',
    };

    for (const enumKey of Object.keys(filterEnum)) {
      const enumValue = filterEnum[enumKey];
      filtersObject.options.push({
        title: enumValue,
        value: enumKey,
        selected: false,
        disabled: false,
        searchResult: false,
      });
    }
    return filtersObject;
  };

  const createEnumFromGuidance = (guidance: Record<string, { description: string }>) => {
    const newEnum: Record<string, string> = {};

    const sortedEntries = Object.entries(guidance).sort(([keyA], [keyB]) => keyB.localeCompare(keyA));
    
    for (const [key, value] of sortedEntries) {
      newEnum[key] = value.description !== '' ? value.description : insightSeverityEnum[key];
    }
    return newEnum;
  };

  return {
    processTaxonomy,
    generateTaxonomyFilters,
    generateSelectedTaxonomy,
    generateAssetFilters,
    generateComponentHealthFilters,
    generateWorkOrderDropdownFilters,
    filtersToQuerystring,
    filtersToUpdate,
    permissionsToFilters,
    combineFilters,
    combineObjects,
    combineAssetFilters,
    resetSelections,
    setSelections,
    allPermissionsToFilters,
    dropdownItemToFilter,
    getInvalidFilters,
    generateFilterFromStringArray,
    generateFilterFromEnum,
    updateDisabledFilters,
    deepEqual,
    isEmpty,
    filterCollectionFilters,
    createEnumFromGuidance

  };
}
