import type { NavigationGuardNext, RouteLocationNormalized } from 'vue-router';
import { useLocalStorage } from '@/utils/localStorageUtils';
import { useTerms } from '@/utils/termsUtils';
import { keycloak } from '@/api/auth';
import { UserRole } from '@/features/admin/userManagement/types/userManagementTypes';
import useAuthStore from '@/features/auth/store/authStore';
import useTenantStore from '@/features/auth/tenant/store/tenantStore';
import { useInitialize } from '@/features/userSavedFilters/composables/useInitialize';

type GuardDelegate = (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
) => Promise<void | boolean>;

// const tenantStore = useTenantStore();

async function guard(to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) {
  if (!useAuthStore().isAuthenticated) {
    await keycloak.login();
  }
  // If the configuration is not loaded yet, call the init() method
  if (!(Object.keys(useTenantStore().config).length > 0)) {
    await useInitialize().init();
  }

  const consent = await useTerms().hasConsented();

  if (!consent && !useTenantStore().skipTermsAndConditions) {
    next(`/Terms?next=${to.path}`);
  }
  if (
    to.matched.some((record) => record.meta.workOrderAnalytics === true) &&
    !useTenantStore().workOrderAnalyticsFeatureEnabled
  ) {
    next('/404');
  }
  next();
}

async function adminGuard(to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) {
  if (useAuthStore().role !== UserRole.TenantAdmin) {
   next('/401');
  }
  next();
}

function defaultGuardProvider(): GuardDelegate {
  return guard;
}

function adminGuardProvider(): GuardDelegate {
  return async (to, from, next) => {
    await adminGuard(to, from, next);
  };
}

function saveCurrentPage(currentPage: string) {
  useLocalStorage().currentPage = currentPage;
}

function savedPageGuardProvider(page: string): GuardDelegate {
  return async (to, from, next) => {
    saveCurrentPage(page);
    await guard(to, from, next);
  };
}

export { guard, adminGuard, saveCurrentPage, defaultGuardProvider, adminGuardProvider, savedPageGuardProvider };
