import { defineStore } from 'pinia';
import { API } from '@/api/client/apiClient';
import type { UserSavedFiltersType, Filters, Filter, FilterObject }  from '@/features/userSavedFilters/types/userSavedFiltersTypes';
import { filterUtils } from '@/utils/filterUtils';

type UserFiltersState = {
  taxonomy: Array<Filter>;
  asset: Array<Filter>;
  loadingTaxonomies?: boolean;
  filter: Filters;
  filterId?: string;
  attributes: Array<string>;
  savedFilters: Array<UserSavedFiltersType>;
  currentSelectedFilter: any;
  temporaryFilter: any;
  defaultSelectedFilter: Object;
  lastFilter: Object;
  temporaryTaxonomy: Array<FilterObject>;
  temporaryAssetTaxonomy: Array<FilterObject>;
  invalidFilters: Array<UserSavedFiltersType>;
  permissions: Object;
  updateTables: Boolean;
  totalAssetCount: Number;
  userTotalAssetCount: Number;
};

function getCollectionItemAll(store:any) {
  const filter = store.getTemporaryFilter.filterName.length === 0
    ? store.getUserCurrentSelectedFilters
    : store.getTemporaryFilter;
  return filter.filters.attributeFilters.collectionFilters;
}

export default defineStore('userFilters', {
  state: (): any => ({
    taxonomy: [],
    asset: [],
    loadingTaxonomies: undefined,
    filter: {},
    filterId: undefined,
    attributes: [],
    savedFilters: [],
    permissions: {},
    currentSelectedFilter: {
      userSavedFilterId: '',
      filterName: '',
      description: '',
      isDefault: false,
      isHidden: false,
      filters: {
        attributeFilters: {
          patternFilters: {},
          collectionFilters: {},
        },
      },
    },
    temporaryFilter: {
      userSavedFilterId: '',
      filterName: '',
      description: '',
      isDefault: false,
      isHidden: false,
      filters: {
        attributeFilters: {
          patternFilters: {},
          collectionFilters: {},
        },
      },
    },
    lastFilter: {
      userSavedFilterId: '',
      filterName: '',
      description: '',
      isDefault: false,
      isHidden: false,
      filters: {
        attributeFilters: {
          patternFilters: {},
          collectionFilters: {},
        },
      },
    },
    defaultSelectedFilter: {},
    temporaryTaxonomy: [],
    temporaryAssetTaxonomy: [],
    invalidFilters: [],
    updateTables: false,
    totalAssetCount: 0,
    userTotalAssetCount: 0,
  }),
  actions: {
    updateUserFilters(payload: any) {
      this.taxonomy = payload.taxonomy ?? this.taxonomy;
      this.asset = payload.asset ?? this.asset;
      this.loadingTaxonomies = payload.loadingTaxonomies ?? false;
      this.filter = payload.filter ?? this.filter;
      this.filterId = payload.filterId ?? this.filterId;
      this.attributes = payload.attributes ?? this.attributes;
      this.savedFilters = payload.savedFilters ?? this.savedFilters;
      this.currentSelectedFilter = payload.currentSelectedFilter ?? this.currentSelectedFilter;
      this.temporaryFilter = payload.temporaryFilter ?? this.temporaryFilter;
      this.lastFilter = payload.lastFilter ?? this.lastFilter;
      this.temporaryTaxonomy = payload.temporaryTaxonomy ?? this.temporaryTaxonomy;
      this.defaultSelectedFilter = payload.defaultSelectedFilter ?? this.defaultSelectedFilter;
      this.temporaryAssetTaxonomy = payload.temporaryAssetTaxonomy ?? this.temporaryAssetTaxonomy;
      this.invalidFilters = payload.invalidFilters ?? this.invalidFilters;
      this.permissions = payload.permissions ?? this.permissions;
      this.updateTables = payload.updateTables ?? this.updateTables;
      this.totalAssetCount = payload.totalAssetCount ?? this.totalAssetCount;
      this.userTotalAssetCount = payload.userTotalAssetCount ?? this.userTotalAssetCount;
      if (payload.permissions) {
        this.setCollectionFiltersToPermissions();
      }
    },
    setCollectionFiltersToPermissions() {
      this.temporaryFilter.filters.attributeFilters.collectionFilters = this.permissions;
      this.lastFilter.filters.attributeFilters.collectionFilters = this.permissions;
    },
    updateUserFilter(payload: string) {
      this.filter = payload;
    },
    updateCurrentSelectedFilter(payload: UserSavedFiltersType) {
      if(payload === undefined) {
        this.currentSelectedFilter = this.defaultSelectedFilter;
        this.filter = this.defaultSelectedFilter.filters.attributeFilters.collectionFilters;
        this.filterId = this.defaultSelectedFilter.userSavedFilterId; 
      }
      else {
        this.currentSelectedFilter = payload;
        this.filter = payload.filters.attributeFilters.collectionFilters;
        this.filterId = payload.userSavedFilterId;  
      }     
    },
 
    updateLastFilter(payload: any) {
      this.lastFilter = payload;
    },
    async updateTotalAssetsCount() {
      const response = await API.Assets.queryAssetCountsByAttributes(this.lastFilter.filters)
      this.totalAssetCount = response.data;
    },
    async updateUserTotalAssetsCount(payload: any) {
      this.userTotalAssetCount = payload;
    },
    async updateUserSavedFilters() {
      const response = await API.UserSavedFilters.getAllUserSavedFilters();
      this.savedFilters = response.data
    },

    updateInvalidFilters(payload: Array<UserSavedFiltersType>) {
      this.invalidFilters = payload;
    },

    updateTemporaryFilter(payload: any) {
      this.temporaryFilter = payload;
    },

    updateTemporaryTaxonomy(payload: any) {
      this.temporaryTaxonomy = payload;
    },

    updateTemporaryAssetTaxonomy(payload: any) {
      this.temporaryAssetTaxonomy = payload;
    },

    updateDefaultSelectedFilter(payload: UserSavedFiltersType) {
      this.defaultSelectedFilter = payload || this.lastFilter;
    },

    async deleteSavedFilter(payload: any) {
      await API.UserSavedFilters.deleteNamedFilters(payload);
      this.updateUserSavedFilters();
    },

    async updateSavedFilter(id: string, updates: any) {
      await API.UserSavedFilters.updateNamedAssetFilter(id, updates);
      this.updateUserSavedFilters();
    },

    async postSavedFilter(payload: any) {
      await API.UserSavedFilters.saveNamedAssetFilter(payload);
      this.updateUserSavedFilters();
    },

    updateAllTables(payload: any) {
      this.updateTables = payload;
    },

    async setTemporaryTaxonomy(defaultTaxonomy:any, defaultAssets:any) {
      const collectionItemAll = getCollectionItemAll(this);
      const collectionItemKeys = Object.keys(collectionItemAll);

      const filterTaxonomy = filterUtils().generateSelectedTaxonomy(defaultTaxonomy, collectionItemAll, collectionItemKeys);
      const filterAssetTaxonomy = filterUtils().generateSelectedTaxonomy(defaultAssets, collectionItemAll, collectionItemKeys);

      this.updateTemporaryTaxonomy(filterTaxonomy);
      this.updateTemporaryAssetTaxonomy(filterAssetTaxonomy);

      const assets = await this.setAssetFilters(defaultAssets);
      this.updateTemporaryAssetTaxonomy(filterUtils().combineAssetFilters(filterAssetTaxonomy, assets));
    },

    async setAssetFilters(defaultAssets:any) {

      // let filters;

      const { filtersToUpdate, generateAssetFilters, generateSelectedTaxonomy } = filterUtils();
      const filters = filtersToUpdate({ ...this.state });

      // const filtersFormatted = filterUtils().filtersToUpdate(filter);

      // const combinedFilters = filterUtils().combineObjects(
      //   JSON.parse(JSON.stringify(this.getUserPermissions)),
      //   filter
      // );

      // const filters = filterUtils().filterCollectionFilters(combinedFilters, this.attributes);

      //  filters = this.getTemporaryFilter.filterName.length === 0
      // ?{ ...this.getUserCurrentSelectedFilters.filters.attributeFilters.collectionFilters }
      // :  { ...this.getTemporaryFilter.filters.attributeFilters.collectionFilters };


      const filterId = this.getTemporaryFilter.filterName.length === 0
        ? this.currentSelectedFilter.userSavedFilterId
        : this.temporaryFilter.userSavedFilterId;

      if (!filters || !filterId) {
        console.error("Filters or Filter ID is not defined");
        return;
      }
      const combinedFilterToPass = filterUtils().combineObjects(JSON.parse(JSON.stringify(this.permissions)), filters);

      const payload = {
        filters: combinedFilterToPass,
        filterId: filterId,
        attributes: this.attributes,
      };

      return API.UserSavedFilters.getAssetsByFiltersAndAttributesPost_1(payload)
        .then((response: any) => {
          // const asset = filterUtils().generateAssetFilters(response.data.filters, tenantStore.config.filters);
          const collectionItemAll = response.data.filters;
          const collectionItemKeys = Object.keys(collectionItemAll);
          const asset = generateAssetFilters(collectionItemAll, this.attributes);
          this.updateTemporaryAssetTaxonomy(generateSelectedTaxonomy(
            defaultAssets,
            collectionItemAll,
            collectionItemKeys
          ));

          return asset;
        })
        .catch((error: any) => {
          console.error(error);
          return [{}];
        });
    },
  },

  getters: {
    state: (state) => ({
      taxonomy: state.taxonomy,
      asset: state.asset,
      loadingTaxonomies: state.loadingTaxonomies,
      filter: state.filter,
      filterId: state.filterId,
      attributes: state.attributes,
      savedFilters: state.savedFilters,
      currentSelectedFilter: state.currentSelectedFilter,
      temporaryFilter: state.temporaryFilter,
      lastFilter: state.lastFilter,
      temporaryTaxonomy: state.temporaryTaxonomy,
      defaultSelectedFilter: state.defaultSelectedFilter,
      temporaryAssetTaxonomy: state.temporaryAssetTaxonomy,
      invalidFilters: state.invalidFilters,
      totalAssetCount: state.totalAssetCount,
      userTotalAssetCount: state.userTotalAssetCount,
    }),
    getInvalidFilters: (state) => {
      return state.invalidFilters;
    },
    getUserPermissions: (state) => {
      return state.permissions;
    },
    getUserSavedFilters: (state) => {
      return state.savedFilters;
    },
    getUserCurrentSelectedFilters: (state) => {
      return state.currentSelectedFilter || state.lastFilter;
    },
    getTemporaryFilter: (state) => {
      return state.temporaryFilter;
    },
    getLastFilter: (state) => {
      return state.lastFilter;
    },
    getTemporaryTaxonomy: (state) => {
      return state.temporaryTaxonomy;
    },
    getTemporaryAssetTaxonomy: (state) => {
      return state.temporaryAssetTaxonomy;
    },
    getDefaultSelectedFilter: (state) => {
      return state.defaultSelectedFilter || state.lastFilter;
    },
    getTotalAssetCount: (state) => {
      return state.totalAssetCount;
    },
    getUserTotalAssetCount: (state) => {
      return state.userTotalAssetCount;
    },
  },
});
