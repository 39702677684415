import Client from '@/api/client/httpClient';

export default class MediaApi extends Client {
  constructor(baseUrl = import.meta.env.VITE_SERVICE_MEDIA) {
    super(baseUrl);
    this.baseUrl = baseUrl;
  }

  getInsightFile(query: string) {
    return this.instance.get(`/media/insights${query}`);
  }

  getStaticImage(query: string) {
    return this.instance.get(`/media/images${query}`);
  }

  getAssetFile(query: string) {
    return this.instance.get(`/media/asset${query}`);
  }

  getNearTimeFaults(assestId: string) {
    return this.instance.get(`/dbx-media-api/fault_snapshot/asset_id/${assestId}`);
  }
}
