import type { Plugin } from 'vue';
import TenantApi from '@/features/auth/tenant/api/TenantApi';
import AdminApi from '@/features/admin/adminSettings/api/AdminApi';
import TaxonomyApi from '@/features/auth/taxonomy/api/TaxonomyApi';
import AuthApi from '@/features/auth/api/AuthApi';
import insightApi from '@/features/insights/api/InsightApi';
import NotificationsApi from '@/features/notifications/api/NotificationsApi';
import AssetsApi from '@/features/assets/api/AssetsApi';
import MediaApi from '@/features/media/api/MediaApi';
import CasesApi from '@/features/cases/api/CasesApi';
import WorkOrdersApi from '@/features/workOrder/workOrderAnalytics/api/WorkOrdersApi';
import UserSavedFiltersApi from '@/features/userSavedFilters/api/UserSavedFiltersApi';
import UserManagementApi from '@/features/admin/userManagement/api/UsersManagementApi';
import ConnectorsApi from '@/features/admin/connectorManagement/api/ConnectorsApi';
import AnalyticsApi from '@/features/dashboards/api/AnalyticsApi';

const Auth = new AuthApi();
const Tenant = new TenantApi();
const Taxonomy = new TaxonomyApi();
const Assets = new AssetsApi();
const Media = new MediaApi();
const Cases = new CasesApi();
const Insight = new insightApi();
const Notifications = new NotificationsApi();
const UserSavedFilters = new UserSavedFiltersApi();
const WorkOrders = new WorkOrdersApi();
const Admin = new AdminApi();
const UserManagement = new UserManagementApi();
const Connectors = new ConnectorsApi();
const Analytics = new AnalyticsApi();

export const API = {
  Auth,
  Assets,
  Taxonomy,
  Insight,
  Media,
  Tenant,
  Cases,
  Notifications,
  UserSavedFilters,
  WorkOrders,
  Admin,
  UserManagement,
  Connectors,
  Analytics
};
const apiClient: Plugin = {
  install: (app) => {
    app.config.globalProperties.$api = API;
    app.provide('api', API);
  },
};

export default apiClient;

declare module '@vue/runtime-core' {
  export interface ComponentCustomProperties {
    $api: typeof API;
  }
}

export type APIType = typeof API;

export function useAPI() {
  return API;
}
