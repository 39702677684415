import type { Filter } from '@/features/userSavedFilters/types/userSavedFiltersTypes';
import { defineStore } from 'pinia';

type GlobalFiltersState = {
  taxonomy: Array<Filter>;
  asset: Array<Filter>;
  loadingTaxonomies?: boolean;
  filter: any;
  filterId: string;
  attributes: Array<string>;
};

export default defineStore('globalFilters', {
  state: (): GlobalFiltersState => ({
    taxonomy: [],
    asset: [],
    loadingTaxonomies: undefined,
    filter: {},
    filterId: '',
    attributes: [],
  }),

  actions: {
    updateGlobalFilters(payload: GlobalFiltersState) {
      this.taxonomy = payload.taxonomy ?? this.taxonomy;
      this.asset = payload.asset ?? this.asset;
      this.loadingTaxonomies = payload.loadingTaxonomies ?? this.loadingTaxonomies;
      this.filter = payload.filter ?? this.filter;
      this.filterId = payload.filterId ?? this.filterId;
      this.attributes = payload.attributes ?? this.attributes;
    },
  },

  getters: {
    state: (state: GlobalFiltersState) => ({
      taxonomy: state.taxonomy,
      asset: state.asset,
      loadingTaxonomies: state.loadingTaxonomies,
      filter: state.filter,
      filterId: state.filterId,
      attributes: state.attributes,
    }),
    filtersJSON: (state: GlobalFiltersState) => {
      return state;
    },
    getGlobalFilterId: (state: GlobalFiltersState) => {
      return state.filterId;
    },
    getGlobalFilter: (state: GlobalFiltersState) => {
      return state.filter;
    },
  },
});
