import Client from '@/api/client/httpClient';
import { schemas } from '@/api/clients/AssetUiApi';
import { z } from 'zod';

type UserSavedAssetFilterRequest = z.infer<typeof schemas.UserSavedAssetFilterRequest>;
type UserSavedFilterDtoAssetQueryDto = z.infer<typeof schemas.UserSavedFilterDtoAssetQueryDto>;
type AssetFiltersDto = z.infer<typeof schemas.AssetFiltersDto>;
type AssetFiltersRequest = z.infer<typeof schemas.AssetFiltersRequest>;

export default class UserSavedFiltersApi extends Client {
  constructor(baseUrl = import.meta.env.VITE_SERVICE_ASSET_UI) {
    super(baseUrl);
    this.baseUrl = baseUrl;
  }

  getAssetsByFiltersAndAttributesPost_1(assetFilterRequest: AssetFiltersRequest) {
    return this.instance.post<AssetFiltersDto>(`/asset-ui/v1/assets/filter`, assetFilterRequest);
  }

  getAllUserSavedFilters() {
    return this.instance.get<UserSavedFilterDtoAssetQueryDto[]>(`/asset-ui/v2/assets/filters`);
  }

  deleteNamedFilters(filter: UserSavedAssetFilterRequest) {
    return this.instance.delete<void>(`/asset-ui/v2/assets/filters`, { data: filter });
  }

  saveNamedAssetFilter(filter: UserSavedAssetFilterRequest) {
    return this.instance.post<UserSavedFilterDtoAssetQueryDto>(`/asset-ui/v2/assets/filters`, filter);
  }

  updateNamedAssetFilter(userSavedFilterId: string, updates: UserSavedAssetFilterRequest) {
    return this.instance.put<UserSavedFilterDtoAssetQueryDto>(`/asset-ui/v2/assets/filters/${userSavedFilterId}`, updates);
  }
}