import Client from '@/api/client/httpClient';
import { schemas } from '@/api/clients/AssetUiApi';
import { z } from 'zod';
import { InsightActionContext } from '@/features/insights/types/insights';
import type { APIListResponse, APIResponse, APIResponseObject } from '@/api/client/client';

type WorkorderDto = z.infer<typeof schemas.WorkorderDto>;
type AssetDto = z.infer<typeof schemas.AssetDto>;
type AssetInsightDto = z.infer<typeof schemas.AssetInsightDto>;
type AssetCaseDto = z.infer<typeof schemas.AssetCaseDto>;
type RiskFactorScore = z.infer<typeof schemas.AssetRiskFactorRiskScoreDto>;
type AssetQueryDto = z.infer<typeof schemas.AssetQueryDto>;
type InsightSummaryDto = z.infer<typeof schemas.InsightsSummaryDto>;

export default class AssetsApi extends Client {
  constructor(baseUrl = import.meta.env.VITE_SERVICE_ASSET_UI) {
    super(baseUrl);
    this.baseUrl = baseUrl;
  }
  //getAssets **
  getAssetsByTenantIdAndAttributes(query: string) {
    return this.instance.get<APIListResponse<AssetDto>>(`/asset-ui/v2/assets${query}`);
  }

  //getAssetHistory **
  async queryAssetInsightActionsPaged(assetId: string, query: any, dataObj: any) {
    return await this.instance.post<APIListResponse<AssetInsightDto>>(
      `/asset-ui/v1/assets/${assetId}/insights${query}`,
      dataObj
    );
  }

  //getAssetWorkOrder **
  getWorkorder(assetId: string, workOrderId: string) {
    return this.instance.get<APIResponse<WorkorderDto>>(`/asset-ui/v1/assets/${assetId}/workorders/${workOrderId}`);
  }

  //getWorkOrdersByQuery **
  getAssetWorkorders(assetId: string, query: string) {
    return this.instance.get<APIListResponse<WorkorderDto>>(`/asset-ui/assets/${assetId}/workorders${query}`);
  }

  //getAssetWorkOrdersCount **
  getAssetWorkorderCount(assetId: string) {
    return this.instance.get<APIResponse<number>>(`/asset-ui/assets/${assetId}/workorders/count`);
  }

  //getAssetsRiskScores **
  getAssetRiskScores(query: string) {
    return this.instance.post<APIListResponse<RiskFactorScore>>(`/asset-ui/v2/assets/risk/scores${query}`);
  }

  //getAssetCountByFilterId **
  getAssetCountsByAttributes(query: string) {
    return this.instance.get<APIResponse<number>>(`/asset-ui/v2/assets/count${query}`);
  }

  //postAssetCountByFilterAttributes **
  queryAssetCountsByAttributes(filterAttributes: AssetQueryDto) {
    return this.instance.post<APIResponse<number>>(`/asset-ui/v2/assets/count`, filterAttributes);
  }

  //getRiskSummary **
  queryAssetRiskScoreSummary(query: string) {
    return this.instance.post<APIResponseObject<any>>(`/asset-ui/v2/assets/risk/scores/summary${query}`);
  }

  //getRiskBySubsystem **
  async getTopRiskFactors(assetId: string) {
    return this.instance.get<APIListResponse<RiskFactorScore>>(`/asset-ui/assets/${assetId}/riskfactors/riskscores`);
  }

  //getAssetInsight **
  getAssetInsight(assetId: string, insightId: string) {
    return this.instance.get<APIResponse<InsightSummaryDto>>(`/asset-ui/assets/${assetId}/insights/${insightId}`);
  }

  //addCommentToAssetInsight **
  addAssetInsightComment(assetId: string, assetInsightId: string, commentContent: string) {
    return this.instance.post<APIResponse<AssetInsightDto>>(
      `/asset-ui/v1/assets/${assetId}/insights/${assetInsightId}/comments`,
      {
        comment: commentContent,
      }
    );
  }

  //applyAssetInsightAction **
  async applyActionToInsight(
    context: InsightActionContext,
    assetId: string,
    assetInsightId: string,
    notes?: string,
    resolutionReason?: string
  ) {
    return this.instance.post(`/asset-ui/assets/${assetId}/insights/${assetInsightId}/action`, {
      customerActionTakenTs: new Date().toISOString(),
      notes,
      resolutionReason,
      action: context === InsightActionContext.disregard ? 'DISREGARDED' : 'ACCEPTED',
    });
  }

  //getAssetSummary **
  async getAssetSummary(assetId: string) {
    return (await this.instance.get<APIResponseObject<AssetDto>>(`/asset-ui/assets/${assetId}/summary`)).data;
  }

  //getActiveInsightCount **
  async queryAssetInsightActionsCount(assetId: string, dataObject: any) {
    return (await this.instance.post<any>(`/asset-ui/v1/assets/${assetId}/insights/count`, dataObject)).data;
  }

  //getAssetCasesHistory **
  findAssetCaseHistory(query: string, assetId: string) {
    return this.instance.get<APIListResponse<AssetCaseDto>>(`/asset-ui/v1/assets/${assetId}/cases/history${query}`);
  }

  //getCasesInDateRange **
  getAssetCaseActiveOn(query: string, assetId: string) {
    return this.instance.get<APIListResponse<AssetCaseDto>>(
      `/asset-ui/v1/assets/${assetId}/cases/active/between${encodeURI(query)}`
    );
  }

  //getAssetCases **
  findCaseAssetsByTenantIdAndAssetAttributes(query: string, data: any) {
    return this.instance.post<APIListResponse<AssetCaseDto>>(`/asset-ui/v1/assets/cases/search${query}`, data);
  }

  //getAssetCaseByState **
  async getAssetCases(query: string, assetId: string) {
    return (await this.instance.get<APIListResponse<AssetCaseDto>>(`/asset-ui/v1/assets/${assetId}/cases${query}`))
      .data;
  }

  //getAssetCasesCount **
  findCaseAssetsCountByTenantIdAndAssetAttributes(query: string, data: any) {
    return this.instance.post<APIResponse<number>>(`/asset-ui/v1/cases/count${query}`, data);
  }

  getAssetSummaries(query: string, data: any) {
    return this.instance.post(`/asset-ui/v1/assets/summaries${query}`, data);
  }
}
